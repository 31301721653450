// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/materials-bg.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".materials{margin-top:80px}.materials__content{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:cover;padding:70px 220px;border-radius:15px;position:relative;display:grid;grid-template-columns:60% 40%;gap:50px;color:var(--white);overflow:hidden}.materials__content:before{content:\"\";background-color:rgba(0,0,0,.6);position:absolute;width:100%;height:100%;top:0;left:0;bottom:0;right:0}.materials-item{position:relative;display:flex;flex-direction:column}.materials-item__title{font-size:30px;line-height:45px;font-weight:700;margin-bottom:55px}@media screen and (max-width:479px){.materials-item__title{font-size:18px;line-height:22px}}.materials-item__links{display:flex;flex-direction:column;gap:10px}.materials-item__links a{display:block;font-size:20px;line-height:30px;font-weight:600;color:var(--white);filter:opacity(90%);border-bottom:1px solid var(--white);max-width:-moz-fit-content;max-width:fit-content}@media screen and (max-width:479px){.materials-item__links a{font-size:14px;line-height:17px}}.materials-item__links a:hover{-webkit-text-stroke-width:.5px}@media screen and (max-width:1151px){.materials__content{padding:40px}}@media screen and (max-width:767px){.materials__content{display:flex;flex-direction:column;background:unset;padding:unset;border-radius:unset;gap:15px}.materials__content:before{display:none}.materials-item{color:var(--black)}.materials-item__title{margin-bottom:15px}.materials-item__links a{color:var(--black);font-weight:400;text-decoration:underline}}", ""]);
// Exports
module.exports = exports;
